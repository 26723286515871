import React, { ChangeEvent, KeyboardEvent, useId, useState } from 'react';
import { Box, FormControl, IconButton, SelectProps } from '@mui/material';
import {
  StyledInputLabelSelectField,
  StyledOutlinedInputSelectField,
  StyledFormHelperTextSelectField,
  StyledMenuItemForSelectField,
  StyledSelectField
} from './style';
import { green, white } from '../../../constants/theme/colors';
import CheckIcon from '@mui/icons-material/Check';
import { sanitizeAndTrim } from '../../../utils/common';

type CustomSelectOptionType = {
  label: string;
  value: string;
};

type CustomSelectFieldProps = SelectProps & {
  helperText: string;
  options: CustomSelectOptionType[] | [];
  allowAdd?: boolean;
  allowAddOptionText?: string;
};

function CustomSelectField({
  id,
  label,
  value,
  onChange,
  error,
  helperText,
  options,
  placeholder,
  sx,
  disabled,
  allowAdd = false,
  allowAddOptionText = 'Add New Option',
  ...props
}: CustomSelectFieldProps) {
  const labelId = useId();

  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOption, setNewOption] = useState('');
  const [customOptions, setCustomOptions] =
    useState<CustomSelectOptionType[]>(options);

  const handleAddNewOption = () => {
    const sanitizedNewOption = sanitizeAndTrim(newOption);
    if (sanitizedNewOption) {
      const newCustomOption = {
        label: sanitizedNewOption,
        value: sanitizedNewOption
      };
      setCustomOptions([newCustomOption, ...customOptions]);
      onChange &&
        onChange(
          { target: { value: newOption } } as ChangeEvent<HTMLInputElement>,
          null
        );
      setIsAddingOption(false);
      setNewOption('');
    }
  };
  return (
    <FormControl fullWidth error={error} variant="outlined">
      <StyledInputLabelSelectField disabled={disabled}>
        {label}
      </StyledInputLabelSelectField>
      <StyledSelectField
        id={id}
        labelId={labelId}
        value={value || ''}
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        input={<StyledOutlinedInputSelectField notched label={label} />}
        sx={sx}
        disabled={disabled}
        {...props}
      >
        {customOptions.map((option) => (
          <StyledMenuItemForSelectField
            value={option?.value}
            key={option.value}
          >
            {option.label}
          </StyledMenuItemForSelectField>
        ))}
        {allowAdd && (
          <Box>
            {!isAddingOption ? (
              <StyledMenuItemForSelectField
                disableRipple={isAddingOption}
                onClick={() => setIsAddingOption(true)}
              >
                {allowAddOptionText}
              </StyledMenuItemForSelectField>
            ) : (
              <StyledMenuItemForSelectField
                disableRipple
                sx={{
                  '&:hover, &.Mui-focusVisible': {
                    backgroundColor: white[500]
                  }
                }}
              >
                <Box className="flex items-center" flex={1}>
                  <StyledOutlinedInputSelectField
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                    fullWidth
                    placeholder='Enter new option'
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      e.stopPropagation();
                      if (e.key === 'Enter') {
                        handleAddNewOption();
                      }
                    }}
                    sx={{ height: (theme) => theme.spacing(4.5) }}
                  />
                  <IconButton
                    aria-label="Add New Option"
                    onClick={handleAddNewOption}
                  >
                    <CheckIcon style={{ color: green[500] }} />
                  </IconButton>
                </Box>
              </StyledMenuItemForSelectField>
            )}
          </Box>
        )}
      </StyledSelectField>
      <StyledFormHelperTextSelectField>
        {helperText}
      </StyledFormHelperTextSelectField>
    </FormControl>
  );
}

export default CustomSelectField;
