import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { closeDialog } from '../../../store/slices/dialogSlice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { Box, Button, ButtonProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDialogTitle, StylesBootstrapDialog } from './style';
import LoadingButton from '../LoadingButton';

interface CustomDialogProps {
  dialogId: string;
  dialogTitle?: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  showDeleteButton?: boolean;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
  onClose?: () => void;
  handleCloseFromTop?: () => void;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  loader?: boolean;
  disableButton?: boolean;
  disableElementButton?: boolean;
  dialogWidth?: string;
  dialogHeight?: string;
  submitButtonType?: 'button' | 'submit' | 'reset' | undefined;
  disableBackDropClick?: boolean;
  primaryButtonProps?: Omit<ButtonProps, 'variant'>;
}



const CustomDialog: React.FC<CustomDialogProps> = ({
  dialogId,
  dialogTitle = '',
  children,
  showCloseButton = false,
  showCancelButton = false,
  showConfirmButton = false,
  showDeleteButton = false,
  onCancelButtonClick = () => null,
  onConfirmButtonClick = () => null,
  onDeleteButtonClick = () => null,
  onClose = () => null,
  cancelButtonLabel = 'Cancel',
  loader = false,
  confirmButtonLabel = 'Confirm',
  disableButton = false,
  disableElementButton = false,
  handleCloseFromTop = () => null,
  dialogHeight,
  dialogWidth,
  submitButtonType = 'button',
  disableBackDropClick = true,
  primaryButtonProps = {}, 
}) => {
  const dispatch = useAppDispatch();
  const openDialogIds = useAppSelector(
    (state) => state.dialogReducer.openDialogIds
  );
  const open = openDialogIds.includes(dialogId);

  // const handleClickOpen = () => {
  //   if (!disableElementButton) dispatch(openDialog(dialogId));
  // };

  const handleClose = () => {
    if (onClose() !== null) {
      onClose();
    } else {
      dispatch(closeDialog(dialogId));
    }
  };

  return (
    <StylesBootstrapDialog
      onClose={(e, reason) => {
        if (disableBackDropClick && reason === 'backdropClick') {
          return;
        } else {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        sx: {
          width: dialogWidth,
          height: dialogHeight,
          borderRadius: '1.75rem',
          minWidth: 'min-content',
          maxWidth: '100%',
          overflowY: 'visible',
          padding: (theme) => theme.spacing(3)
        }
      }}
    >
      {dialogTitle && (
        <StyledDialogTitle
          sx={{ textTransform: 'capitalize' }}
          id="customized-dialog-title"
        >
          {dialogTitle}
        </StyledDialogTitle>
      )}
      {showCloseButton && (
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            handleCloseFromTop();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {showDeleteButton && (
        <IconButton
          aria-label="close"
          onClick={() => {
            onDeleteButtonClick();
          }}
          sx={{
            position: 'absolute',
            right: 48,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <DialogContent sx={{ overflowY: 'auto !important' }}>
        {children}
      </DialogContent>
      {(showCancelButton || showConfirmButton) && (
        <Box className="flex" gap={2} justifyContent="end">
          {showCancelButton && (
            <Button
              variant="text"
              color="primary"
              onClick={onCancelButtonClick}
            >
              {cancelButtonLabel}
            </Button>
          )}
          {showConfirmButton && (
            <LoadingButton
              type={submitButtonType}
              variant="outlined"
              color="primary"
              onClick={onConfirmButtonClick}
              disabled={disableButton || loader}
              loading={loader}
              {...primaryButtonProps}
            >
              {confirmButtonLabel}
            </LoadingButton>
          )}
        </Box>
      )}
    </StylesBootstrapDialog>
  );
};

export default CustomDialog;
