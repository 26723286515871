import { createSlice } from '@reduxjs/toolkit';
import { severity } from '../../constants/app-constant';

const initialState = {
    open: false,
    severity: severity.info,
    message: null,
    position: {}
};

const snackBarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        resetSnackBar: (state) => initialState,
        setSnackBar: (state, action) => {
            return {
              ...state,
              severity: action.payload.severity,
              message: action.payload.message,
              open: true,
              position: action.payload.position ?? {},
            };
        },
    },
});

export const { resetSnackBar, setSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;