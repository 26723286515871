import React, { ReactElement, forwardRef, useRef, ForwardedRef } from 'react';
import { Snackbar, Slide, SlideProps, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { resetSnackBar } from '../../../store/slices/snackbarSlice';
import { severity } from '../../../constants/app-constant';
import { svg } from '../../../assets/svg';

// Define the props type for Alert
interface AlertForwardRefProps extends AlertProps {}

const Alert = forwardRef<HTMLDivElement, AlertForwardRefProps>(function Alert(
  props,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = (): ReactElement => {
  const dispatch = useDispatch();
  const alertRef = useRef<HTMLDivElement>(null);
  const {
    open,
    severity: selectedSeverity,
    message,
    position
  } = useSelector((state: RootState) => state.snackBar);
  const vertical = 'bottom';
  const horizontal = 'center';

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetSnackBar());
  };

  const TransitionUp = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
  };

  return (
    <div>
      {open && (
        <Stack spacing={2} sx={{ width: 1 }}>
          <Snackbar
            open={open}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={TransitionUp}
            sx={{
              maxWidth: '480px',
              width: 1,
            }}
            style={{
              ...(Object.keys(position).length > 0 && { position: 'fixed', ...position })
            }}
          >
            <Alert
              onClose={handleClose}
              severity={
                selectedSeverity === severity.success
                  ? 'success'
                  : selectedSeverity === severity.error
                  ? 'error'
                  : selectedSeverity === severity.warning
                  ? 'warning'
                  : 'info'
              }
              sx={{ width: 1 }}
              ref={alertRef}
              iconMapping={{
                success: (
                  <Box
                    component="img"
                    src={svg.checkGreenIcon}
                    alt="check-icon"
                  />
                ),
                error: (
                  <Box
                    component="img"
                    src={svg.toastErrorIcon}
                    alt="error-icon"
                  />
                )
              }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </div>
  );
};

export default CustomSnackBar;
