import { createTheme, PaletteOptions } from '@mui/material';

export const primary = {
  475: '#60A5FA',
  500: '#3B82F6',
  525: '#2563EB',
  600: '#2797CF',
  700: '#1877F2'
};

export const secondary = {
  300: '#6e7db7',
  500: '#55608d',
  800: '#3b4363'
};

export const black = {
  50: 'rgba(0, 0, 0, 0.10)',
  100: 'rgba(0, 0, 0, 0.54)',
  200: '#1E293B',
  300: '#334155',
  400: '#243043',
  500: '#0F172A',
  900: '#000000'
};

export const white = {
  500: '#FFFFFF'
};

export const grey = {
  200: '#64748B',
  250: '#C9D3E0',
  300: '#94A3B8',
  400: '#CBD5E1',
  500: '#74757E',
  550: '#696F77',
  600: '#DFDFDF',
  700: '#868686',
  900: '#CCCCCC'
};

export const blue = {
  100: '#EFF6FF',
  200: '#495972',
  300: 'rgba(59, 130, 246, 0.2)',
  500: '#475569'
};

export const green = {
  100: '#DCFCE7',
  200: '#84CC16',
  300: 'rgba(132, 204, 22, 1)',
  400: 'rgb(48, 203, 144)',
  500: '#16A34A',
  600: '#84CC16'
};

export const red = {
  100: '#FCE2DC',
  400: '#FF937B'
};

export const bgPalette = {
  100: '#F5F9FF',
  500: '#292935',
  600: '#313149'
};

export const borderPalette = {
  100: '#BFDBFE',
  200: '#DADADA',
  300: '#DEDEDE',
  500: '#E2E8F0'
};

export const themePalette = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: black[900],
      white: white[500],
      grey: '#cccccc'
    },
    primary: {
      main: primary[500]
    },
    success: {
      main: green[100]
    }
  } as PaletteOptions
});
