import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { RouteConstants } from '../../../../constants/route-constant';

interface PrivateRouteProps {
  redirectTo?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectTo = RouteConstants.ROUTE_LOGIN
}) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isSubscribed = useAppSelector((state) => state.auth.isSubscribed);
  const isPaymentPages =
    location.pathname === RouteConstants.ROUTE_HOME ||
    location.pathname === RouteConstants.ROUTE_TERMS_CONDITION;

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} state={{ from: location }} replace={true} />;
  }

  if (!isSubscribed && !isPaymentPages) {
    return <Navigate to={RouteConstants.ROUTE_HOME} replace={true} />;
  }

  if (isSubscribed && isPaymentPages) {
    return <Navigate to={RouteConstants.ROUTE_PROJECT} replace={true} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
