import { MenuItem, styled, FormHelperText, OutlinedInput, InputLabel, Select } from '@mui/material';
import { black, blue, borderPalette, grey, white } from '../../../constants/theme/colors';

export const StyledMenuItemForSelectField = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Readex Pro',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  color: grey[550],
  '&.Mui-selected': {
    backgroundColor: blue[300]
  },
  '&:hover': {
    backgroundColor: black[50]
  }
}));

export const StyledFormHelperTextSelectField = styled(FormHelperText)(({ theme }) => ({
  fontFamily: 'Readex Pro',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px'
}));

export const StyledOutlinedInputSelectField = styled(OutlinedInput)(({ theme }) => ({
  fontFamily: 'Readex Pro',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: grey[550]
}));

export const StyledInputLabelSelectField = styled(InputLabel)(({ theme }) => ({
  fontFamily: 'Readex Pro',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
  transform: 'translate(20px, -6px) scale(1)', 
  color: black[500],
  '& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.error.light
  },
  '& .MuiInputLabel-root.Mui-error.Mui-focused': {
    color: theme.palette.error.dark
  },
  '&.Mui-disabled': {
    color: black[500],
    backgroundColor: white[500]
  },
}));

export const StyledSelectField = styled(Select)(({ theme, placeholder }) => ({
  borderRadius: '8px',
  height: '56px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: grey[550],
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '0px 16px',
    borderColor: grey[400],
    '& legend': {
      fontSize: '12px'
    }
  },
  '& .MuiSelect-outlined': {
    fontFamily: 'Readex Pro',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: grey[550]
  },
  '& .MuiSelect-select .notranslate::after': placeholder
    ? {
        content: `"${placeholder}"`,
        opacity: 1,
        color: grey[300]
      }
    : {},
  '& .MuiSelect-nativeInput': {
    fontFamily: 'Readex Pro',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& .MuiSelect-outlined.Mui-disabled': {
    backgroundColor: borderPalette[500],
    color: grey[300],
    WebkitTextFillColor: grey[300]
  },
}));