import React from 'react';
import { Box, Typography } from '@mui/material';
import { primary } from '../../constants/theme/colors';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavTabProps {
  label: string;
  to: string;
}

function NavTab({ label, to }: NavTabProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const onTabClick =() => {
    navigate(to)
  }
  return (
    <Box
      px={1.25}
      className="flex flex-column justify-space-between cursor-pointer"
      alignSelf="stretch"
      onClick={onTabClick}
    >
      <Box className="flex items-center" flex={1} position="relative">
        <Typography variant="mec_body13" color="white">
          {label}
        </Typography>
        {location.pathname === to && (
          <Box
            position="absolute"
            sx={{
              bottom: 0,
              left: 0,
              width: 1,
              height: '4px',
              borderRadius: '3px 3px 0px 0px',
              backgroundColor: primary[500]
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default NavTab;
