import { Avatar, IconButton, Typography, Menu, Box } from '@mui/material';
import React from 'react';
import { black, blue, white } from '../../constants/theme/colors';
import { svg } from '../../assets/svg';
import { UserProfileMenuItem } from './style';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logout } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../constants/route-constant';
import { getNameInitials, isPlaceholderAvatar } from '../../utils/common';
import { DialogConstants } from '../../constants/app-constant';
import CustomDialog from '../Shared/CustomDialog';
import { closeDialog, openDialog } from '../../store/slices/dialogSlice';

function UserProfileMenu() {
  const dispatch = useAppDispatch()
  const { logout:auth0Logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useAppSelector((state) => state.auth?.user);
  const isSubscribed = useAppSelector((state) => state.auth.isSubscribed);
  const navigate = useNavigate();
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManageSubscription = () => {
    navigate(RouteConstants.ROUTE_MANAGE_SUBSCRIPTION);
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await auth0Logout();
      await logout();
    } catch (error) {
      console.log(error,"error");
    }
  };

  const onHandleCloseDialog = () => {
    dispatch(closeDialog(DialogConstants.dialogIds.CONFIRM_LOGOUT_DIALOG));
  }
  
  const onLogoutClick = () => {
    handleClose();
    dispatch(openDialog(DialogConstants.dialogIds.CONFIRM_LOGOUT_DIALOG));
  }

  return (
    <>
      <IconButton
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar
          sx={{ bgcolor: black[200], width: '40px', height: '40px' }}
          src={user?.profileUrl && !isPlaceholderAvatar(user?.profileUrl) ? user?.profileUrl : undefined}
        >
          {(!user?.profileUrl || isPlaceholderAvatar(user?.profileUrl)) && user?.name &&(
            <Typography variant="mec_body7">
              {getNameInitials(user?.name)}
            </Typography>
          )}
        </Avatar>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          sx: {
            padding: '7px 0px',
            borderRadius: '4px',
            backgroundColor: black[200],
            color: white[500],
            width: '200px'
          }
        }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: black[200]
          }
        }}
      >
        {isSubscribed && (
          <UserProfileMenuItem
            disableGutters
            onClick={handleManageSubscription}
          >
            <Box
              width="44px"
              height="48px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                component="img"
                src={svg?.moneyWhiteIcon}
                width="24px"
                height="24px"
                alt="subscription-icon"
              />
            </Box>
            <Typography variant="mec_body13" color="white">
              Manage Subscription
            </Typography>
          </UserProfileMenuItem>
        )}
        <UserProfileMenuItem disableGutters onClick={onLogoutClick}>
          <Box
            width="44px"
            height="48px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={svg?.computerRedIcon}
              width="24px"
              height="24px"
              alt="logout-icon"
            />
          </Box>
          <Typography variant="mec_body13" color="white">
            Logout
          </Typography>
        </UserProfileMenuItem>
      </Menu>
      <CustomDialog
        dialogId={DialogConstants.dialogIds.CONFIRM_LOGOUT_DIALOG}
        dialogTitle="Logout?"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Yes, Logout"
        showConfirmButton={true}
        showCancelButton={true}
        submitButtonType="submit"
        dialogWidth="364px"
        dialogHeight="auto"
        onConfirmButtonClick={handleLogout}
        onClose={onHandleCloseDialog}
        onCancelButtonClick={onHandleCloseDialog}
        primaryButtonProps={{
          sx: {
            width:'10rem'
          }
        }}
      >
        <Box>
          <Typography variant="mec_body16" color={blue[500]}>
            You are about to be logged out. Do you wish to proceed?
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
}

export default UserProfileMenu;
