import { createTheme } from '@mui/material';
import { blue, black, red, themePalette } from './colors';

export const themeComponents = createTheme(themePalette, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "'Readex Pro', 'Arial', 'Helvetica', sans-serif",
          backgroundColor: blue[100]
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: "'Readex Pro', 'Arial', 'Helvetica', sans-serif",
          fontSize:'16px',
          fontWeight: 400,
          lineHeight: '20px',
          color: black[900],
          maxWidth: '480px',
          width: 1,
          borderRadius: '6px',
        },
        filledError: {
          backgroundColor: red[100],
          color: black[900]
        },
      },
    },
  }
});
