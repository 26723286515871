import { combineReducers } from 'redux';

import authReducer from './slices/authSlice';
import snackBarReducer from './slices/snackbarSlice';
import projectReducer from './slices/project.slice';
import companyReducer from './slices/companySlice';
import dialogReducer from './slices/dialogSlice'

const rootReducer = combineReducers({
  auth: authReducer,
  snackBar: snackBarReducer,
  project: projectReducer,
  company: companyReducer,
  dialogReducer: dialogReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;