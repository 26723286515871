import { createSlice } from '@reduxjs/toolkit';

interface companyDetailsType {
  companyName: string;
  databaseType: string;
  contactName: string;
  email: string;
  dataSize: string;
}

interface initialStateType {
  isRegistered: boolean;
  companyDetails: null | companyDetailsType;
}

const initialState: initialStateType = {
  isRegistered: false,
  companyDetails: null
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setRegistered: (state, action) => {
      state.isRegistered = action.payload;
    },
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    }
  }
});

export const { setRegistered, setCompanyDetails } = companySlice.actions;

export default companySlice.reducer;
