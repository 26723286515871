import { createSlice } from '@reduxjs/toolkit';

interface initialStateType {
  databaseType: null | string;
}

const initialState: initialStateType = {
  databaseType: null
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectDatabaseType: (state, action) => {
      state.databaseType = action.payload;
    }
  }
});

export const { setProjectDatabaseType } = projectSlice.actions;

export default projectSlice.reducer;
