import { svg } from '../assets/svg';
import {
  ConnectionFormInitialValueType,
  DetailsFormInitialValueType
} from '../types/form';

// All app constants will be here in this file

// Plan details - TODO: this will need to be expanded with the multiple plans in the future
export const planCheckList = [
  'Connectivity to any of 5 supported data sources (we are adding more continuously)',
  'Evaluation of data quality, consistency and readiness for monetization',
  'Data schema and data samples for productization',
  'A guideline value of the data',
  'Continuous monitoring of the data set as it grows or is remediated'
];

export const databaseTypeString = {
  POSTGRESQL: 'pg',
  MYSQL: 'mysql',
  MICROSOFTSQL: 'microsoftSql',
  SNOWFLAKE: 'snowflake',
  AMAZONSHIFT: 'amazonShift'
};

// List of supported platforms
export const platformsWithIcon = [
  {
    name: 'PostgreSQL',
    iconSrc: svg.postgresqlLogo,
    hoverIcon: svg.postgresqlLogo,
    databaseType: databaseTypeString.POSTGRESQL
  },
  {
    name: 'MySQL',
    iconSrc: svg.mysqlLogo,
    hoverIcon: svg.mysqlHoverLogo,
    databaseType: databaseTypeString.MYSQL
  },
  {
    name: 'Microsoft SQL server',
    iconSrc: svg.microsoftSqlLogo,
    hoverIcon: svg.microsoftSqlLogo,
    databaseType: databaseTypeString.MICROSOFTSQL
  },
  {
    name: 'Snowflake',
    iconSrc: svg.snowflakeLogo,
    hoverIcon: svg.snowflakeLogo,
    databaseType: databaseTypeString.SNOWFLAKE
  },
  {
    name: 'Amazon Redshift',
    iconSrc: svg.amazonShiftLogo,
    hoverIcon: svg.amazonShiftLogo,
    databaseType: databaseTypeString.AMAZONSHIFT
  }
];

export const databaseSizeOptions = [
  { label: '<100GB', value: '<100Gb' },
  { label: '100-500GB', value: '100-500GB' },
  { label: '>500GB', value: '>500GB' }
];

export const termsconditionsText = {
  title: 'Terms and Conditions for Xferdata',
  describe:
    'By accessing or using our services, you agree to be bound by these Terms and Conditions. These terms govern your use of our software-as-a-service (SaaS) platform, including all features, content, and services provided by Xferdata.',
  keyTerms: [
    'User Responsibilities: You agree to use the Xferdata platform in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account and credentials and for all activities that occur under your account.',
    'License and Usage: Upon subscription, we grant you a limited, non-exclusive, non-transferable license to access and use Xferdata. You may not resell, sublicense, or otherwise commercially exploit the platform without our express permission.',
    'Data and Privacy: At Xferdata, we respect your privacy and are committed to protecting your personal data. Our use of your data is governed by our Privacy Policy, which you agree to by using our platform.',
    'Fees and Payment: All subscription fees for Xferdata are non-refundable. You are responsible for any applicable taxes and will provide accurate billing information. We reserve the right to modify fees with prior notice.',
    'Termination: We reserve the right to suspend or terminate your access to Xferdata if you violate these Terms and Conditions. Upon termination, your right to use the platform will cease immediately.',
    'Limitation of Liability: Xferdata will not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangibles arising from your use of the platform.',
    'Changes to Terms: We may update these Terms and Conditions from time to time. Your continued use of Xferdata after any changes constitutes acceptance of the new terms.',
    'User Responsibilities: You agree to use the Xferdata platform in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account and credentials and for all activities that occur under your account.',
    'License and Usage: Upon subscription, we grant you a limited, non-exclusive, non-transferable license to access and use Xferdata. You may not resell, sublicense, or otherwise commercially exploit the platform without our express permission.',
    'Data and Privacy: At Xferdata, we respect your privacy and are committed to protecting your personal data. Our use of your data is governed by our Privacy Policy, which you agree to by using our platform.',
    'Fees and Payment: All subscription fees for Xferdata are non-refundable. You are responsible for any applicable taxes and will provide accurate billing information. We reserve the right to modify fees with prior notice.',
    'Termination: We reserve the right to suspend or terminate your access to Xferdata if you violate these Terms and Conditions. Upon termination, your right to use the platform will cease immediately.',
    'Limitation of Liability: Xferdata will not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangibles arising from your use of the platform.',
    'Changes to Terms: We may update these Terms and Conditions from time to time. Your continued use of Xferdata after any changes constitutes acceptance of the new terms.'
  ],
  rightToCollect:
    'Confirm that you have the right to collect the data in your dataset',
  rightToSell: 'Confirm you have the right to sell the data',
  footerNote: `Note: You can proceed, but marketplace bids won't be possible until these are fixed.`
};

export const initialConnectionFormValues: ConnectionFormInitialValueType = {
  account: '',
  database: '',
  schema: '',
  username: '',
  password: '',
  verifyPassword: ''
};

export const severity = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
};

export const auth0ConnectionString = {
  linkedIn: 'linkedin',
  github: 'github',
  google: 'google-oauth2',
  windowsLive: 'windowslive',
  apple: 'apple'
};

export const initialDetailsFormValues: DetailsFormInitialValueType = {
  companyName: '',
  databaseType: '',
  contactName: '',
  dataSize: '',
  email: ''
};


export const DialogConstants = {
  dialogIds: {
    CONFIRM_LOGOUT_DIALOG: 'confirmLogoutDialog',
  }
}