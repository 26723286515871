import React from 'react';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { black } from '../../constants/theme/colors';
import InputField from '../Shared/InputField';
import { Formik, Field, FieldProps, FormikHelpers } from 'formik';
import {
  auth0ConnectionString,
  databaseSizeOptions,
  initialDetailsFormValues,
  platformsWithIcon,
  severity
} from '../../constants/app-constant';
import { detailsFormValidation } from '../../utils/validation';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getErrorMessage } from '../../utils/getMessageFromError';
import { setSnackBar } from '../../store/slices/snackbarSlice';
import { registerCompany } from '../../services/company.service';
import { HTTP_OK } from '../../constants/status-code';
import CustomSelectField from '../Shared/CustomSelectField';
import {
  setCompanyDetails,
  setRegistered
} from '../../store/slices/companySlice';
import LoadingButton from '../Shared/LoadingButton';
import { setUser } from '../../store/slices/authSlice';
import { sanitizeAndTrim } from '../../utils/common';

function DetailsForm() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const handleSubmitForm = async (
    values: typeof initialDetailsFormValues,
    actions: FormikHelpers<typeof initialDetailsFormValues>
  ) => {
    try {
      actions.setSubmitting(true);
      const payload = {
        companyName: sanitizeAndTrim(values.companyName),
        databaseType: sanitizeAndTrim(values.databaseType),
        contactName: sanitizeAndTrim(values.contactName),
        email: sanitizeAndTrim(values.email),
        dataSize: sanitizeAndTrim(values.dataSize),
        oktaId: auth.user?.oktaId ?? '',
        loginType: auth.loginType === auth0ConnectionString.linkedIn ? 'linkedIn': auth.loginType 
      };
      const response = await registerCompany(payload);
      if (response && response.data && response.status === HTTP_OK) {
        dispatch(setRegistered(true));
        if (response.data.companyDetails) {
          dispatch(setCompanyDetails(response.data.companyDetails));
          const userEmail = response.data.companyDetails.email
          dispatch(
            setUser({ ...auth.user, email: userEmail || null })
          );
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage, 'errormessage');
      dispatch(
        setSnackBar({
          severity: severity.error,
          message: errorMessage
        })
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box py="34px" className="flex flex-column" sx={{ maxWidth: '628px' }}>
      <Box py={2}>
        <Typography variant="mec_body8" color={black[900]}>
          Your Details
        </Typography>
      </Box>
      <Formik
        initialValues={{
          ...initialDetailsFormValues,
          email: auth.user?.email ?? ''
        }}
        validationSchema={detailsFormValidation}
        onSubmit={handleSubmitForm}
      >
        {({
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          isValid
        }) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <Box py={2}>
              <Grid container rowSpacing={4} columnSpacing={1.5}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Field name="companyName">
                    {({ field }: FieldProps) => (
                      <InputField
                        label="Company Name"
                        name={field.name}
                        value={field.value}
                        placeholder="Enter company name"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.companyName && !!errors.companyName}
                        helperText={
                          touched.companyName && errors.companyName
                            ? errors.companyName
                            : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Field name="databaseType">
                    {({ field }: FieldProps) => (
                      <CustomSelectField
                        id="databaseTypeField"
                        name={field.name}
                        value={field.value}
                        label="Database Type"
                        placeholder="Select database"
                        fullWidth
                        options={platformsWithIcon.map((platform) => ({
                          label: platform.name,
                          value: platform.databaseType
                        }))}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                        }}
                        onBlur={handleBlur}
                        error={touched.databaseType && !!errors.databaseType}
                        helperText={
                          touched.databaseType && errors.databaseType
                            ? errors.databaseType
                            : ''
                        }
                        allowAdd={true}
                        allowAddOptionText="+ Other database"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Field name="contactName">
                    {({ field }: FieldProps) => (
                      <InputField
                        name={field.name}
                        value={field.value}
                        label="Contact"
                        placeholder="Enter contact name"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.contactName && !!errors.contactName}
                        helperText={
                          touched.contactName && errors.contactName
                            ? errors.contactName
                            : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Field name="dataSize">
                    {({ field }: FieldProps) => (
                      <CustomSelectField
                        id="dataSizeField"
                        name={field.name}
                        value={field.value}
                        label="Approximate Data Size"
                        placeholder="Select size"
                        options={databaseSizeOptions}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                        }}
                        onBlur={handleBlur}
                        error={touched.dataSize && !!errors.dataSize}
                        helperText={
                          touched.dataSize && errors.dataSize
                            ? errors.dataSize
                            : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                {!auth.user?.email && (
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Field name="email">
                      {({ field }: FieldProps) => (
                        <InputField
                          name={field.name}
                          value={field.value}
                          label="Email"
                          placeholder="Enter your email"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && !!errors.email}
                          helperText={
                            touched.email && errors.email ? errors.email : ''
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box className="flex flex-wrap" gap={1} py={2}>
              <Box width="250px">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                >
                  Register
                </LoadingButton>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default DetailsForm;
