import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { black } from '../../../constants/theme/colors';

interface MiniBlackBgProps extends BoxProps {
  children: ReactNode;
}

function MiniBlackBg({ children, sx = {}, ...rest }: MiniBlackBgProps) {
  return (
    <Box
      sx={{ width: 1, height: '280px', backgroundColor: black[500], ...sx }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default MiniBlackBg;
