import { createTheme, ThemeOptions } from '@mui/material';
import { themePalette } from './colors';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [key: string]: true;
  }
}
interface ExtendedTypographyOptions extends TypographyOptions {
  [key: string]: React.CSSProperties | any;
}

export const themeTypography = createTheme(themePalette, {
  typography: {
    fontFamily: "'Readex Pro', 'Arial', 'Helvetica', sans-serif",
    mec_h1: {
      fontSize: '55px',
      fontWeight: 500,
      lineHeight: '68px',
      fontFamily: 'Readex Pro'
    },
    mec_h2: {
      fontSize: '44px',
      fontWeight: 700,
      lineHeight: '60px',
      fontFamily: 'Readex Pro'
    },
    mec_h3: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '50px',
      fontFamily: 'Readex Pro'
    },
    mec_h4: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',
      fontFamily: 'Readex Pro'
    },
    mec_h5: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '42px',
      fontFamily: 'Readex Pro'
    },
    mec_h6: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '50px',
      fontFamily: 'Readex Pro'
    },
    mec_subtitle1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
      fontFamily: 'Readex Pro'
    },
    mec_subtitle2: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '40px',
      fontFamily: 'Readex Pro'
    },
    mec_subtitle3: {
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '28px',
      fontFamily: 'Readex Pro'
    },
    mec_body1: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      fontFamily: 'Readex Pro'
    },
    mec_body2: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '30px',
      fontFamily: 'Readex Pro'
    },
    mec_body3: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '25px',
      fontFamily: 'Readex Pro'
    },
    mec_body4: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
      fontFamily: 'Readex Pro'
    },
    mec_body5: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontFamily: 'Readex Pro'
    },
    mec_body6: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      fontFamily: 'Readex Pro'
    },
    mec_body7: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22.4px',
      fontFamily: 'Readex Pro'
    },
    mec_body8: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      fontFamily: 'Readex Pro'
    },
    mec_body9: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontFamily: 'Readex Pro'
    },
    mec_body10: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      fontFamily: 'Readex Pro'
    },
    mec_body11: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      fontFamily: 'Readex Pro'
    },
    mec_body12: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      fontFamily: 'Readex Pro'
    },
    mec_body13: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px'
    },
    mec_body14: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.1px',
      fontFamily: 'Readex Pro'
    },
    mec_body15: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      fontFamily: 'Readex Pro'
    },
    mec_body16: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      fontFamily: 'Readex Pro'
    },
    mec_body17: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17.5px',
      fontFamily: 'Readex Pro'
    },
    mec_caption1: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      fontFamily: 'Readex Pro'
    },
    mec_caption2: {
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      fontFamily: 'Readex Pro'
    }
  } as ExtendedTypographyOptions
} as ThemeOptions);
