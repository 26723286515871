import { Box, CircularProgress } from '@mui/material';

const SuspenseFallback = () => {
  return (
    <Box className="flex justify-center items-center" sx={{ width: '100vw', height: '100vh' }}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default SuspenseFallback;