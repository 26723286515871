import { createSlice } from '@reduxjs/toolkit';

interface UserType {
  name: string;
  oktaId: string;
  refId: string;
  profileUrl: string
  email?: string
}

interface initialStateType {
  accessToken: null | string;
  refreshToken: null | string;
  isAuthenticated: boolean;
  user: null | UserType;
  isSubscribed: false;
  loginType: null | string;
}

const initialState: initialStateType = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  user: null,
  isSubscribed: false,
  loginType: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearAuth: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.user = null;
      state.isSubscribed = false;
      state.loginType = null;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload;
    },
  }
});

export const { setAuthTokens, setUser, setAuthenticated, clearAuth, setSubscribed, setLoginType } =
  authSlice.actions;

export default authSlice.reducer;
