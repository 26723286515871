import companyLogo from '../svg/company-logo.svg';
import loginPagePlaceholder from '../svg/login-page-placeholder.svg';
import appleIcon from '../svg/apple-icon.svg';
import googleIcon from '../svg/google-icon.svg';
import githubIcon from '../svg/github-icon.svg';
import linkedInIcon from '../svg/linkedin-icon.svg';
import windowsIcon from '../svg/windows-icon.svg';
import computerRedIcon from '../svg/computer-red-icon.svg';
import moneyWhiteIcon from '../svg/money-white-icon.svg';
import moneyWithWingsIcon from '../svg/money-with-wings.svg';
import checkGreenIcon from '../svg/check-green-icon.svg';
import amazonShiftLogo from '../svg/amazon-shift-logo.svg';
import microsoftSqlLogo from '../svg/microsoft-sql-logo.svg';
import mysqlLogo from '../svg/mysql-logo.svg';
import postgresqlLogo from '../svg/postgresql-logo.svg';
import snowflakeLogo from '../svg/snowflake-logo.svg';
import bookmarkIcon from '../svg/bookmark-icon.svg';
import arrowForwardIcon from '../svg/arrow-forward-icon.svg';
import phoneIcon from '../svg/phone-icon.svg';
import thumbDownIcon from '../svg/thumb-down-icon.svg';
import thumbOkIcon from '../svg/thumb-ok-icon.svg';
import errorOutlineGreyIcon from '../svg/error-outline-grey-icon.svg';
import noReportPlaceholder from '../svg/no-report-placeholder.svg';
import visaCardIcon from '../svg/visa-card-icon.svg';
import checkBoxCheckedPrimaryIcon from '../svg/checkbox-checked-primary-icon.svg';
import toastErrorIcon from '../svg/toast-error-icon.svg';
import mysqlHoverLogo from '../svg/mysql-hover-logo.svg';
import bgGradient from '../svg/background-gradient.svg';

export const svg = {
  companyLogo: companyLogo,
  loginPagePlaceholder: loginPagePlaceholder,
  appleIcon: appleIcon,
  googleIcon: googleIcon,
  githubIcon: githubIcon,
  linkedInIcon: linkedInIcon,
  windowsIcon: windowsIcon,
  computerRedIcon: computerRedIcon,
  moneyWhiteIcon: moneyWhiteIcon,
  moneyWithWingsIcon: moneyWithWingsIcon,
  checkGreenIcon: checkGreenIcon,
  amazonShiftLogo: amazonShiftLogo,
  microsoftSqlLogo: microsoftSqlLogo,
  mysqlLogo: mysqlLogo,
  postgresqlLogo: postgresqlLogo,
  snowflakeLogo: snowflakeLogo,
  bookmarkIcon: bookmarkIcon,
  phoneIcon: phoneIcon,
  arrowForwardIcon: arrowForwardIcon,
  thumbDownIcon: thumbDownIcon,
  thumbOkIcon: thumbOkIcon,
  errorOutlineGreyIcon: errorOutlineGreyIcon,
  noReportPlaceholder: noReportPlaceholder,
  visaCardIcon: visaCardIcon,
  checkBoxCheckedPrimaryIcon: checkBoxCheckedPrimaryIcon,
  toastErrorIcon: toastErrorIcon,
  mysqlHoverLogo: mysqlHoverLogo,
  bgGradient: bgGradient
};
