import { TextField, styled } from '@mui/material';
import {
  black,
  borderPalette,
  grey,
  primary,
  white
} from '../../../constants/theme/colors';

export const StyledConnectTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '56px',
    borderRadius: '8px',
    fontFamily: 'Readex Pro',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: grey[550],
    '& fieldset': {
      borderColor: grey[400],
      paddingLeft: '16px',
      paddingRight: '16px',
      '& legend': {
        fontSize: '12px'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: borderPalette[500],
      color: grey[300],
      WebkitTextFillColor: grey[300],
      '& fieldset':{
        borderColor: grey[400]  
      },
      '&:hover fieldset': {
        borderColor: grey[400]
      }
    },
  },
  '& .MuiFormHelperText-root': {
    fontFamily: 'Readex Pro',
    fontSize: '12px',
    lineHeight: '16px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Readex Pro',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: black[500],
    transform: 'translate(22px, -6px) scale(1)'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: primary[500]
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.error.light
  },
  '& .MuiInputLabel-root.Mui-error.Mui-focused': {
    color: theme.palette.error.dark
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: grey[300],
    opacity: 1
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: black[500],
    backgroundColor: white[500]
  },
}));
