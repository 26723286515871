import React, { useEffect } from 'react';
import { displayVersion } from './utils/console-reporting';
import Routes from './routes/Routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './constants/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { environment } from './environments/environment';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store/store';
import { auth0AudienceUrl } from './constants/api-urls';
import CustomSnackBar from './components/Shared/CustomSnackbar';

const App: React.FC = () => {
  useEffect(() => {
    displayVersion();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider
          domain={environment.auth0Domain}
          clientId={environment.auth0ClientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            scope: 'openid profile email offline_access',
            audience: auth0AudienceUrl,
            max_age: 100
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
            <CustomSnackBar />
          </ThemeProvider>
        </Auth0Provider>
      </PersistGate>
    </Provider>
  );
};

export default App;
