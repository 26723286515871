import { alpha, createTheme, Theme } from '@mui/material';
import {
  borderPalette,
  grey,
  primary,
  themePalette,
  bgPalette
} from './colors';

import '@mui/material/Button';

export const themeButtons: Theme = createTheme(themePalette, {
  palette: themePalette.palette,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'white',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        fontFamily: 'Readex Pro'
      },
      styleOverrides: {
        root: {
          borderRadius: '40px',
          textTransform: 'capitalize',
          minWidth: 'fit-content',
          whiteSpace: 'nowrap',
          fontFamily: 'Readex Pro'
        }
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            height: '40px',
            backgroundColor: themePalette.palette.primary.main,
            '&:hover': {
              backgroundColor: primary[525]
            },
            '&:disabled': {
              backgroundColor: borderPalette[500],
              color: grey[300]
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: bgPalette[100],
            border: `1px solid ${borderPalette[100]}`,
            color: themePalette.palette.primary.main,
            '&:hover': {
              color: primary[525]
            },
            '&:disabled': {
              backgroundColor: alpha(bgPalette[100], 0.5),
              color: alpha(primary[475], 0.5)
            }
          }
        }
      ]
    }
  }
});
