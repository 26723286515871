import React from 'react';
import { StyledConnectTextField } from './style';
import { TextFieldProps } from '@mui/material';

const InputField: React.FC<TextFieldProps> = ({ slotProps = {}, ...props }) => {
  return (
    <StyledConnectTextField
      autoComplete="off"
      {...props}
      slotProps={{
        ...slotProps,
        inputLabel: {
          shrink: true
        }
      }}
    />
  );
};

export default InputField;
