import { styled, DialogTitle, Dialog } from '@mui/material';
import { black } from '../../../constants/theme/colors';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: 'Readex Pro',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 400,
  color: black[500],
  padding: 0,
  margin: 0,
  paddingBottom: theme.spacing(2)
}));

export const StylesBootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
    scrollbarWidth: 'auto',
    width: '100%'
  }
}));
