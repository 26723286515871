export const RouteConstants = {
  ROUTE_LOGIN: '/login' as const,
  ROUTE_HOME: '/' as const,
  ROUTE_SUBSCRIPTION: '/subscription' as const,
  ROUTE_PROJECT: '/project' as const,
  ROUTE_DATA_VALUATION: '/data-valuation' as const,
  ROUTE_NO_REPORT: '/no-report' as const,
  ROUTE_MANAGE_SUBSCRIPTION: '/manage-subscription' as const,
  ROUTE_TERMS_CONDITION: '/terms-condition' as const,
} as const;
