import { createTheme } from '@mui/material/styles';

import { themeTypography } from './typography';
import { themeButtons } from './button';
import { themePalette } from './colors';
import { themeComponents } from './components';
// import { themeInputs } from "./inputs";

const theme = createTheme(
  themePalette,
  themeTypography,
  themeButtons,
  themeComponents,
  // themeInputs,
);

export default theme;
