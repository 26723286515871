import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom
} from 'react-router-dom';
import { RouteConstants } from '../constants/route-constant';
import Login from '../pages/Login';
import PrivateRoute from '../components/Shared/RouteWrapper/PrivateRoute';
import ProtectedRoute from '../components/Shared/RouteWrapper/ProtectedRoute';
import Home from '../pages/Home';
// import Project from '../pages/Project';
// import DataValuation from '../pages/DataValuation';
// import NoReport from '../components/DataValuation/NoReport';
// import ManageSubscription from '../pages/ManageSubscription';
// import TermsCondition from '../pages/TermsCondition';
import SuspenseFallback from '../components/Shared/SuspenseFallback';

const Routes: React.FC = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <RoutesReactRouterDom>
        <Route element={<ProtectedRoute />}>
          <Route path={RouteConstants.ROUTE_LOGIN} element={<Login />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={RouteConstants.ROUTE_HOME} element={<Home />} />
          {/* <Route
            path={RouteConstants.ROUTE_TERMS_CONDITION}
            element={<TermsCondition />}
          />
          <Route path={RouteConstants.ROUTE_PROJECT} element={<Project />} />
          <Route
            path={RouteConstants.ROUTE_DATA_VALUATION}
            element={<DataValuation />}
          />
          <Route path={RouteConstants.ROUTE_NO_REPORT} element={<NoReport />} />
          <Route
            path={RouteConstants.ROUTE_MANAGE_SUBSCRIPTION}
            element={<ManageSubscription />}
          /> */}
        </Route>

        <Route path="*" element={<Navigate to={RouteConstants.ROUTE_HOME} />} />
      </RoutesReactRouterDom>
    </Suspense>
  );
};

export default Routes;
