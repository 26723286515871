import React from 'react';
import { black, primary, white } from '../../constants/theme/colors';
import { Box, Typography } from '@mui/material';
import { gif } from '../../assets/gif';

function RegistrationSuccess() {
  return (
    <Box
      className="flex justify-center"
      flex={1}
      bgcolor={black[500]}
      sx={{
        py: '130px',
        px: 3
      }}
    >
      <Box className="flex flex-column items-center" gap="70px">
        <Box
          component="img"
          src={gif.successGif}
          alt="success-gif"
          maxWidth="200px"
          width={1}
        />
        <Box className="flex flex-column items-center text-center">
          <Typography variant="mec_h2" color={white[500]}>
            Data Monetization
          </Typography>
          <Typography variant="mec_h2" color={primary[500]}>
            You have made the first step!
          </Typography>
          <Typography variant="mec_body4" color={white[500]} mt={2}>
            We will get back in touch soon!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default RegistrationSuccess;
