import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { Box, Typography, Slide, CircularProgress } from '@mui/material';
import MiniBlackBg from '../../components/Shared/MiniBlackBg';
import { grey, white } from '../../constants/theme/colors';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import DetailsForm from '../../components/CompanyRegistration/DetailsForm';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import RegistrationSuccess from '../../components/CompanyRegistration/RegistrationSuccess';
import { getCompanyDetails } from '../../services/company.service';
import {
  setCompanyDetails,
  setRegistered
} from '../../store/slices/companySlice';
import { HTTP_OK } from '../../constants/status-code';
import { getErrorMessage } from '../../utils/getMessageFromError';

function Home() {
  const dispatch = useAppDispatch();
  const isCompanyRegistered = useAppSelector(
    (state) => state.company.isRegistered
  );
  const auth = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getCompanyDetailsApiCall = async (email: string) => {
      try {
        setLoading(true);
        const response = await getCompanyDetails({ email });
        if (response && response.data && response.status === HTTP_OK) {
          dispatch(setCompanyDetails(response.data.companyDetails));
          dispatch(setRegistered(true));
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage, 'errormessage');
      } finally {
        setLoading(false);
      }
    };
    if (auth.user?.email) {
      getCompanyDetailsApiCall(auth.user?.email);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress size={22} />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      {!isCompanyRegistered ? (
        <Box flex={1}>
          <MiniBlackBg sx={{ height: (theme) => theme.spacing(15) }}>
            <Box px={5} py="34px" className="flex flex-column" gap={3}>
              <Box>
                <Typography
                  component="div"
                  variant="mec_body16"
                  color={grey[200]}
                >
                  Your Details
                </Typography>
                <Typography
                  component="div"
                  variant="mec_subtitle3"
                  color={white[500]}
                  display="flex"
                  alignItems="center"
                  gap="5px"
                >
                  Let Us Know About Your Data{' '}
                  <ElectricBoltIcon
                    style={{ fontSize: '22px', color: 'yellow' }}
                  />
                </Typography>
              </Box>
            </Box>
          </MiniBlackBg>
          <Slide direction="up" in={true}>
            <Box px="40px">
              <DetailsForm />
            </Box>
          </Slide>
        </Box>
      ) : (
        <RegistrationSuccess />
      )}
    </Layout>
  );
}

export default Home;
