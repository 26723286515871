import { firebaseAuthorizationUrl } from '../constants/api-urls';
import { RouteConstants } from '../constants/route-constant';
import { clearAuth } from '../store/slices/authSlice';
import store, { persistor } from '../store/store';
import axiosInstance from '../utils/axiosInstance';

export async function logout() {
  store.dispatch(clearAuth());
  await persistor.purge();
  await persistor.flush();
  window.location.href = RouteConstants.ROUTE_LOGIN;
}

export async function firebaseAuthorize(accessToken: string) {
  const headers = {
    Authorization: 'Bearer ' + accessToken,
  };
  return axiosInstance.post(firebaseAuthorizationUrl, {}, { headers });
}
