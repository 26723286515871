import { Button } from '@mui/material';
import React from 'react';
import { LoadingSpan, StyledCircularProgress } from './style';

interface LoadingButtonProps {
  loading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  children: React.ReactNode;
  [x: string]: any;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading = false,
  startIcon = null,
  endIcon = null,
  variant,
  children,
  disabled,
  ...rest
}) => {
  return (
    <Button
      startIcon={!loading ? startIcon : null}
      endIcon={!loading ? endIcon : null}
      variant={variant}
      disabled={loading ? true : disabled}
      {...rest}
    >
      <LoadingSpan loading={loading}>{children}</LoadingSpan>
      {loading && <StyledCircularProgress size={18} />}
    </Button>
  );
};

export default LoadingButton;
