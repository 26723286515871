import axios from 'axios';
import store, { persistor } from '../store/store';
import { setAuthTokens, clearAuth } from '../store/slices/authSlice';
import { auth0RefreshTokenUrl } from '../constants/api-urls';
import { RouteConstants } from '../constants/route-constant';
import { getRefreshTokenFromLocalStorage, updateTokensInLocalStorage } from './common';
import { unAuthorizedMessage } from '../constants/toast-message';
import { HTTP_UNAUTHORIZED } from '../constants/status-code';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // const state = store.getState();

    // Check if the error is due to expired access token
    if (error.response?.status === HTTP_UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Call Auth0 API to refresh the token using the refresh token
        const refreshToken = await getRefreshTokenFromLocalStorage();

        if (!refreshToken) {
          throw new Error(unAuthorizedMessage);
        }

        const response = await axios.post(auth0RefreshTokenUrl, {
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          refresh_token: refreshToken
        });

        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;
        await updateTokensInLocalStorage({ newAccessToken, newRefreshToken });

        store.dispatch(
          setAuthTokens({
            accessToken: newAccessToken,
          })
        );

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        // Handle token refresh failure
        persistor.purge();
        store.dispatch(clearAuth());
        localStorage.clear();
        window.location.href = RouteConstants.ROUTE_LOGIN; // Redirect to login page
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
