import React, { useRef } from 'react';
import Layout from '../../components/Layout';
import { Box, Typography } from '@mui/material';
import { svg } from '../../assets/svg';
import { primary, grey, black, blue } from '../../constants/theme/colors';
import SocialButtons from '../../components/Login/Social';

function Login() {
  const rightSide = useRef<HTMLDivElement>(null);
  return (
    <Layout>
      <Box display="flex" flex={1}>
        <Box
          sx={{
            width: '432px',
            backgroundColor: black[500],
            p: '22px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pt: '165px',
            gap: '240px'
          }}
        >
          <Box display="flex" flexDirection="column" flex={1} px={2.5}>
            <Typography component="h2" variant="mec_h4" color="white">
              Get started with{' '}
              <Typography
                component="span"
                variant="mec_h4"
                color={primary[500]}
              >
                your data
              </Typography>{' '}
              <Typography
                component="span"
                variant="mec_h4"
                color={primary[500]}
              >
                monetizations
              </Typography>
            </Typography>{' '}
            <Typography
              component="p"
              variant="mec_body6"
              color={grey[300]}
              mt={2.5}
            >
              Unlock insights and value from your data effortlessly
            </Typography>
          </Box>
          <Box sx={{ maxWidth: '320px', height: '306px', mt: 'auto' }}>
            <Box
              component="img"
              src={svg?.loginPagePlaceholder}
              alt="loginPagePlaceholder"
              width="100%"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flex={1}
          sx={{
            backgroundColor: blue[100],
            p: 2,
            pt: '150px'
          }}
          ref={rightSide}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={5}
            flex={1}
            alignItems="center"
            maxWidth="550px"
          >
            <Box width="100%" textAlign="center">
              <Typography component="h2" variant="mec_h2" fontWeight={500} color={black[500]}>
                Register For Early Access
              </Typography>
              <Typography component="p" variant="mec_body3" color={black[500]} mt="5px">
                Join a select group of data owners to shape and optimize our platform for your needs.
              </Typography>
            </Box>
            <Box maxWidth="457px" width="100%">
              <SocialButtons rightSide={rightSide} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default Login;
