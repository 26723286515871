import { environment } from '../environments/environment';

export const auth0AudienceUrl = `https://${environment.auth0Domain}/api/v2/`;
export const auth0RefreshTokenUrl = `https://${environment.auth0Domain}/oauth/token`;

//auth
export const firebaseAuthorizationUrl = `${environment.firebaseCloudFunctionBaseUrl}/authorizeToken`;

//project connection
export const projectConnectionUrl = `${environment.firebaseCloudFunctionBaseUrl}/createDatabaseConnection`;


//company 
export const registerCompanyUrl = `${environment.firebaseCloudFunctionBaseUrl}/registerCompany`;
export const getCompanyDetailsUrl = `${environment.firebaseCloudFunctionBaseUrl}/getCompanyDetails`;