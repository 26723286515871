import React, { ReactNode, RefObject, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  ButtonProps,
  CircularProgress
} from '@mui/material';
import { svg } from '../../../assets/svg';
import { black, grey, primary } from '../../../constants/theme/colors';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../../store/hooks';
import {
  setAuthTokens,
  setAuthenticated,
  setLoginType,
  setUser
} from '../../../store/slices/authSlice';
import { firebaseAuthorize } from '../../../services/auth.service';
import { getErrorMessage } from '../../../utils/getMessageFromError';
import { setSnackBar } from '../../../store/slices/snackbarSlice';
import {
  auth0ConnectionString,
  severity
} from '../../../constants/app-constant';
import { HTTP_OK } from '../../../constants/status-code';
import { signInSuccessMessage } from '../../../constants/toast-message';

interface SocialButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
  children: ReactNode;
  bgColor: string;
  color?: string;
  boxShadow?: boolean;
  showBtnLoader?: boolean;
}

interface SocialButtonsProps {
  rightSide: RefObject<HTMLDivElement>;
}

function SocialButton({
  children,
  bgColor,
  color = 'white',
  sx = {},
  boxShadow = false,
  onClick,
  disabled,
  showBtnLoader = false,
  ...props
}: SocialButtonProps) {
  return (
    <Button
      onClick={!disabled ? onClick : undefined}
      {...props}
      sx={{
        ...sx,
        ...(boxShadow && {
          boxShadow:
            '0px 2px 3px rgba(0, 0, 0, 0.17), 0px 3px 4px rgba(0, 0, 0, 0.08)',
          '&:hover': {
            boxShadow:
              '0px 2px 3px rgba(0, 0, 0, 0.17), 0px 3px 4px rgba(0, 0, 0, 0.08)'
          }
        }),
        height: '50px',
        justifyContent: 'flex-start',
        bgcolor: bgColor,
        color: color,
        '&.Mui-disabled': {
          bgcolor: bgColor,
          color: color
        }
      }}
      disabled={disabled}
    >
      <>
        {children}
        {showBtnLoader && (
          <Box ml="auto" alignSelf="center" display="flex">
            <CircularProgress size={20} sx={{ color }} />
          </Box>
        )}
      </>
    </Button>
  );
}

function SocialButtons({ rightSide }: SocialButtonsProps) {
  const { getAccessTokenWithPopup } = useAuth0();
  const dispatch = useAppDispatch();
  const rightContainerRect = rightSide.current?.getBoundingClientRect?.();
  const [connectionStr, setConnectionStr] = useState('');
  const [showBtnLoader, setShowBtnLoader] = useState(false);

  const loginWith = async (connection: string) => {
    try {
      setConnectionStr(connection);
      const width = 500;
      const height = 500;
      const left = window.screenX + (window.innerWidth - width) / 2;
      const top = window.screenY + (window.innerHeight - height) / 2;
      const accessToken = await getAccessTokenWithPopup(
        {
          cacheMode: 'off',
          authorizationParams: {
            connection,
            prompt: 'login'
          }
        },
        {
          popup: window.open(
            window.location.origin,
            'auth0:authorize:popup',
            `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`
          )
        }
      );
      setShowBtnLoader(true);

      if (accessToken) {
        const response = await firebaseAuthorize(accessToken);
        if (response && response.data && response.status === HTTP_OK) {
          const firebaseUser = response.data?.user || {};
          dispatch(setAuthTokens({ accessToken }));
          dispatch(setAuthenticated(true));
          dispatch(setUser(firebaseUser));
          dispatch(setLoginType(connection));
          dispatch(
            setSnackBar({
              severity: severity.success,
              message: signInSuccessMessage
            })
          );
        }
      }
    } catch (error) {
      console.log(error, 'error');
      const errorMessage = getErrorMessage(error);
      dispatch(
        setSnackBar({
          severity: severity.error,
          message: errorMessage,
          position: {
            left: rightContainerRect
              ? rightContainerRect.left + rightContainerRect.width / 2
              : 200,
            bottom: '25px'
          }
        })
      );
    } finally {
      setConnectionStr('');
      setShowBtnLoader(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="30px"
      width="100%"
    >
      <SocialButton
        fullWidth
        color="white"
        bgColor={primary[600]}
        boxShadow
        onClick={() => loginWith(auth0ConnectionString.linkedIn)}
        disabled={!!connectionStr}
        showBtnLoader={showBtnLoader && connectionStr === auth0ConnectionString.linkedIn}
      >
        <Box
          component="img"
          src={svg?.linkedInIcon}
          alt="linkedInIcon"
          mr={1.25}
        />
        <Typography variant="mec_body3" color="white">
          Sign In with Linkedin
        </Typography>
      </SocialButton>
      <Box display="flex" alignItems="center" width="100%" gap="6px">
        <Box
          sx={{
            border: '1px solid',
            borderColor: grey[300],
            opacity: 0.25,
            flex: 1
          }}
        ></Box>
        <Typography variant="mec_body17" color={grey[300]}>
          Or login with
        </Typography>
        <Box
          sx={{
            border: '1px solid',
            borderColor: grey[300],
            opacity: 0.25,
            flex: 1
          }}
        ></Box>
      </Box>
      <SocialButton
        color="white"
        fullWidth
        bgColor={grey[700]}
        onClick={() => loginWith(auth0ConnectionString.github)}
        disabled={!!connectionStr}
        showBtnLoader={showBtnLoader && connectionStr === auth0ConnectionString.github}
      >
        <Box component="img" src={svg.githubIcon} alt="githubIcon" mr={1.25} />
        <Typography variant="mec_body3" color="white">
          Sign In with Github
        </Typography>
      </SocialButton>
      <SocialButton
        color="black"
        bgColor="white"
        fullWidth
        boxShadow
        onClick={() => loginWith(auth0ConnectionString.google)}
        disabled={!!connectionStr}
        showBtnLoader={showBtnLoader && connectionStr === auth0ConnectionString.google}
      >
        <Box component="img" src={svg.googleIcon} alt="googleIcon" mr={1.25} />
        <Typography variant="mec_body2" color={black[100]}>
          Sign In with Google
        </Typography>
      </SocialButton>
      {/* <SocialButton
        color="white"
        fullWidth
        bgColor={primary[700]}
        onClick={() => loginWith(auth0ConnectionString.windowsLive)}
        disabled={!!connectionStr}
        showBtnLoader={showBtnLoader && connectionStr === auth0ConnectionString.windowsLive}
      >
        <Box
          component="img"
          src={svg.windowsIcon}
          alt="windowsIcon"
          mr={1.25}
        />
        <Typography variant="mec_body3" color="white">
          Sign In with WindowsLive
        </Typography>
      </SocialButton>
      <SocialButton
        color="white"
        fullWidth
        bgColor="black"
        onClick={() => loginWith(auth0ConnectionString.apple)}
        disabled={!!connectionStr}
        showBtnLoader={showBtnLoader && connectionStr === auth0ConnectionString.apple}
      >
        <Box component="img" src={svg.appleIcon} alt="appleIcon" mr={1.25} />
        <Typography variant="mec_body3" color="white">
          Sign In with Apple
        </Typography>
      </SocialButton> */}
    </Box>
  );
}

export default SocialButtons;
