import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { black } from '../../constants/theme/colors';
import { svg } from '../../assets/svg';
import { useAppSelector } from '../../store/hooks';
import NavTab from './NavTab';
import UserProfileMenu from './UserProfileMenu';
import { RouteConstants } from '../../constants/route-constant';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isSubscribed = useAppSelector((state) => state.auth.isSubscribed);
  const onLogoClick = () => {
    navigate(
      !isAuthenticated
        ? RouteConstants.ROUTE_LOGIN
        : isSubscribed
        ? RouteConstants.ROUTE_PROJECT
        : RouteConstants.ROUTE_HOME
    );
  }

  return (
    <AppBar
      sx={{
        backgroundColor: black[500],
        px: 5,
        borderBottom: '1px solid',
        borderBottomColor: black[300],
        height: '65px',
        justifyContent: 'center'
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', px: '0 !important' }}>
        <Box component="img" src={svg.companyLogo} alt="XFERDATA" height={40} sx={{ cursor: 'pointer' }} onClick={onLogoClick} />

        {isAuthenticated && (
          <Box
            className="flex flex-row items-center"
            gap={1.5}
            alignSelf="stretch"
          >
            {isSubscribed && (
              <>
                <NavTab label="Project" to={RouteConstants.ROUTE_PROJECT} />
                <NavTab
                  label="Data Valuation"
                  to={RouteConstants.ROUTE_DATA_VALUATION}
                />
              </>
            )}
            <UserProfileMenu />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
