import React from 'react';
import Navbar from '../Navbar';
import { Box } from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <Box className="flex flex-column" sx={{ minHeight: '100vh' }}>
      <Navbar />
      <Box
        sx={{
          flexGrow: 1,
          pt: '65px',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 65px)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
