import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { RouteConstants } from '../../../../constants/route-constant';

interface ProtectedRouteProps {
  redirectTo?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectTo = RouteConstants.ROUTE_HOME }) => {
  const isAuthenticated =  useAppSelector(state => state.auth.isAuthenticated)
  
  if (isAuthenticated) {
    return <Navigate to={redirectTo} replace={true}/>;
  }

  return <Outlet />;
};

export default ProtectedRoute;