import * as Yup from 'yup';
import { invalidEmailError, isRequired, passwordMatchError, termsAcceptError } from '../constants/validation-message';

export const connectionFormValidation = Yup.object({
  account: Yup.string().required(isRequired('Account')),
  database: Yup.string().required(isRequired('Database')),
  schema: Yup.string().required(isRequired('Schema')),
  username: Yup.string().required(isRequired('Username')),
  password: Yup.string().required(isRequired('Password')),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], passwordMatchError)
    .required(isRequired('Verify Password')),
}); 


export const termsAndConditionValidation = Yup.object({
  terms: Yup.object({
    rightToCollect: Yup.boolean().oneOf([true], termsAcceptError),
    rightToSell: Yup.boolean().oneOf([true], termsAcceptError),
  }).test(
    'all-terms-accepted',
    termsAcceptError,
    (value) => value.rightToCollect && value.rightToSell
  ),
});


export const detailsFormValidation = Yup.object({
  companyName: Yup.string().required(isRequired('Company name')),
  databaseType: Yup.string().required(isRequired('Database type')),
  contactName: Yup.string().required(isRequired('Contact name')),
  dataSize: Yup.string().required(isRequired('Data size')),
  email: Yup.string().email(invalidEmailError).required(isRequired('Email')),
});