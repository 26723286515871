import { styled } from '@mui/material';
import { CircularProgress } from '@mui/material';

export const LoadingSpan = styled('span')<{ loading: boolean }>(
  ({ loading }) => ({
    opacity: loading ? 0 : 1
  })
);

export const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
});
