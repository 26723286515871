import { Cacheable, LocalStorageCache } from '@auth0/auth0-react';
import DOMPurify from 'dompurify';

export const getNameInitials = (name: string) => {
  if (!name) return ''; // Handle case when name is undefined or empty
  const nameParts = name.split(' ');
  const firstNameInitial = nameParts[0]?.charAt?.(0)?.toUpperCase() || '';
  const lastNameInitial = nameParts[1]?.charAt?.(0)?.toUpperCase() || '';
  return `${firstNameInitial}${lastNameInitial}`;
};

// Function to check if the profile URL is a placeholder for different providers
export const isPlaceholderAvatar = (profileUrl: string) => {
  if (!profileUrl) return true;

  const placeholderPatterns = [
    /lh3\.googleusercontent\.com\/a\//, // Google
    /media\.licdn\.com\/dms\/image\/C5603AQF/, // LinkedIn (example pattern)
    /avatars\.githubusercontent\.com\/u\/[0-9]+\?v=4/, // GitHub
    /secure\.gravatar\.com\/avatar\//, // Windows Live / Gravatar
    /appleid\.apple\.com/, // Apple (check for apple-specific URLs)
    /cdn\.auth0\.com\/avatars\/[a-z]{2}\.png/, // Auth0 avatars (used for LinkedIn, etc.)
  ];

  return placeholderPatterns.some((pattern) => pattern.test(profileUrl));
};

export const getRefreshTokenFromLocalStorage = async (): Promise<
  string | null
> => {
  try {
    const cache = new LocalStorageCache();
    const key = cache.allKeys().find((key) => key.includes('auth0spa'));

    if (!key) {
      console.warn('Auth0 SPA key not found in local storage');
      return null;
    }

    const cachedData = (await cache.get(key)) as Cacheable | undefined;

    if (cachedData && 'body' in cachedData) {
      const refreshToken = (cachedData as { body: { refresh_token: string } })
        .body.refresh_token;

      if (refreshToken) {
        // localStorage.setItem('refresh_token', refreshToken); if want to store
        console.log('Refresh token saved in local storage.');
        return refreshToken;
      } else {
        console.warn('Refresh token not found in cached data.');
        return null;
      }
    } else {
      console.warn('Invalid data structure in cached data.');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving refresh token from local storage:', error);
    return null;
  }
};

interface updateRefreshTokenInLocalStoragePayload {
  newRefreshToken: string;
  newAccessToken: string;
}

export const updateTokensInLocalStorage = async ({
  newRefreshToken,
  newAccessToken
}: updateRefreshTokenInLocalStoragePayload): Promise<void> => {
  try {
    const cache = new LocalStorageCache();
    const key = cache.allKeys().find((key) => key.includes('auth0spa'));

    if (!key) {
      console.warn('Auth0 SPA key not found in local storage');
      return;
    }

    const cachedData = (await cache.get(key)) as Cacheable | undefined;

    if (cachedData && 'body' in cachedData) {
      const updatedCacheData = {
        ...cachedData,
        body: {
          ...cachedData.body,
          refresh_token: newRefreshToken,
          access_token: newAccessToken 
        }
      };

      cache.set(key, updatedCacheData);
      console.log('Refresh token updated in local storage.');
    } else {
      console.warn('Invalid cached data structure.');
    }
  } catch (error) {
    console.error('Error updating refresh token in local storage:', error);
  }
};

export function sanitizeAndTrim(value: string) {
  // Trim whitespace and sanitize the input
  return DOMPurify.sanitize(value?.trim());
}